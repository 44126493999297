<template>
  <v-card>
    <v-card-title class="lightgray">
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        label="Buscar"
        clearable
        outlined
        solo
        flat
        dense
        hide-details
      ></v-text-field>
      <v-btn
        style="background-color: #ffffff !important;"
        outlined
        class="toolbar--text ml-6"
        height="40px"
        @click="display = !display"
      >
        <v-icon>filter_list</v-icon>
      </v-btn>

      <!-- <v-spacer></v-spacer>
      <v-btn dark depressed small color="toolbar" @click="gerarExcel">
        <v-icon left>get_app</v-icon>
        Excel
      </v-btn> -->
    </v-card-title>
    <Filtro P_cidade P_status :P_statusArray="status" :display.sync="display" />
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table
        v-if="clientes"
        :headers="headers"
        :search="search"
        :items="clientes"
        :items-per-page="10"
        :sort-by="['created_at']"
        :sort-desc="true"
        class="data-tables"
        :class="{ 'data-tables__row-click': vueVerCliente }"
        @click:row="goToCliente"
      >
        <template v-slot:item.status="{ item }">
          {{ item.status ? "Ativo" : "Inativo" }}
        </template>
      </v-data-table>
      <Carregando v-else />
    </v-card-text>
  </v-card>
</template>

<script>
import { getClientes } from "@/services/api/clientes.api.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "ListaClientes",
  components: {
    Filtro: () => import("@/components/global/Filtro.vue"),
  },
  data: () => ({
    loading: true,
    clientes: null,
    search: "",
    status: [
      {
        text: "Ativo",
        value: "1",
      },
      {
        text: "Inativo",
        value: "0",
      },
    ],
    display: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Documento",
          value: "documento",
        },
        {
          text: "Telefone",
          value: "telefone",
        },
        {
          text: "Cidade",
          value: "cidade",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
    },
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?${queryString}`;
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueVerCliente() {
      return this.getAccess("vueCliente", "visualizar");
    },
  },
  watch: {
    url() {
      this.getClientes();
    },
  },
  methods: {
    goToCliente(item) {
      if (this.vueVerCliente) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.nome);
        this.$router.push({
          name: "Cliente",
          params: { clienteId: item.id },
        });
      }
    },
    getClientes() {
      this.loading = true;
      this.clientes = null;
      this.$Progress.start();
      getClientes(this.url)
        .then((response) => {
          this.clientes = response;
          this.$Progress.finish();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
          this.loading = false;
        });
    },
  },
  created() {
    this.getClientes();
  },
};
</script>

<style scoped lang="scss"></style>
